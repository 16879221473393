<template>
  <b-card class="user-wallet">
    <b-row>
      <div class="col-md-3 mb-2">
        <b-card
          no-body
          class="card-wallet-info p-1 mb-0"
        >
          <b-card-header class="pb-1">
            <h5 class="mb-0">
              {{ $t('Wallet Balance') }}
            </h5>
          </b-card-header>
          <b-card-body v-if="walletList">
            {{ walletList.wallet_balance }} {{ walletList.currency_symbol }}
          </b-card-body>
        </b-card>
      </div>
      <div class="col-md-9">
        <b-form-radio-group
          v-model="walletOptionsSelected"
          :options="walletOptions"
          value-field="item"
          text-field="name"
          class="demo-inline-spacing justify-content-center"
        />
        <b-card-code
          v-if="walletOptionsSelected == '0'"
          :title="$t('Add Money in Wallet')"
          class="mb-0"
        >
          <b-row>
            <div class="col-md-4">
              <b-form-group
                :label="labelInfo"
                label-for="walletAmount"
              >
                <b-form-input
                  id="walletAmount"
                  v-model="amountToAdd"
                  type="number"
                  placeholder="Enter amount"
                />
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group
                :label="$t('Select Card')"
                label-for="ddlSelectCard"
              >
                <v-select
                  v-model="selectedCard"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="cardOptions"
                  :reduce="val => val.value"
                  :clearable="false"
                  input-id="ddlSelectCard"
                />
              </b-form-group>
            </div>
            <div class="my-auto col-md-4">
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="addMoneyToUser"
              >
                {{ $t('Add Money') }}
              </b-button>
            </div>
          </b-row>
        </b-card-code>
        <b-card-code
          v-if="walletOptionsSelected == '1'"
          :title="$t('Send Money to Wallet')"
          class="mb-0"
        >
          <b-row>
            <div class="col-md-4">
              <b-form-group
                :label="$t('Mobile')"
                label-for="mobileNumber"
              >
                <VuePhoneNumberInput
                  ref="userPhoneNumber"
                  v-model="mobileNumber"
                  placeholder="+91 0000000000"
                />
                <!-- <b-form-input
                  id="mobileNumber"
                  v-model="mobileNumber"
                  type="number"
                  placeholder="Enter mobile number"
                /> -->
              </b-form-group>
            </div>
            <div class="col-md-4">
              <b-form-group
                :label="$t('Enter Amount')"
                label-for="sendAmount"
              >
                <b-form-input
                  id="sendAmount"
                  v-model="sendAmount"
                  type="number"
                  placeholder="Enter amount"
                />
              </b-form-group>
            </div>
            <div class="my-auto col-md-4">
              <b-button
                variant="primary"
                class="mb-1 mb-sm-0 mr-0 mr-sm-1"
                :block="$store.getters['app/currentBreakPoint'] === 'xs'"
                @click="sendMoneyToUser"
              >
                {{ $t('Send Money') }}
              </b-button>
            </div>
          </b-row>
        </b-card-code>
        <b-card-code
          v-if="walletOptionsSelected == '2'"
          :title="$t('Scan the QR Code')"
          class="mb-0"
        >
          <b-row>
            <div class="col-md-4">
              <img
                v-if="walletList"
                :src="$helpers.imageHelper(walletList.qrcode_url)"
                class="w-100 h-100"
                @error="$helpers.imageError"
              >
            </div>
          </b-row>
        </b-card-code>
      </div>
    </b-row>
    <b-row>
      <b-col cols="12">
        <good-data-table
          ref="walletGrid"
          :columns="walletColumns"
          :total-column="'response.responseData.total'"
          :data-column="'response.responseData.data'"
          :api-endpoint="'/user/wallet'"
        />
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BCol, BCard, BFormGroup, BFormInput, BCardHeader, BCardBody, BButton, BFormRadioGroup } from 'bootstrap-vue'
import vSelect from 'vue-select'
import VuePhoneNumberInput from 'vue-phone-number-input'
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useUserUi } from './useUser'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'

export default {
  components: {
    BRow,
    BCol,
    GoodDataTable,
    BCard,
    BCardCode,
    BFormGroup,
    BFormInput,
    BCardHeader,
    BCardBody,
    vSelect,
    BButton,
    BFormRadioGroup,
    VuePhoneNumberInput,
  },
  data() {
    return {
      walletColumns: [
        {
          label: 'Transaction Id',
          field: 'transaction_alias',
        },
        {
          label: 'Description',
          field: 'transaction_desc',
        },
        {
          label: 'Status',
          field: 'type',
          rField: 'type_text',
          align: 'center',
          useResolver: true,
          useRenderer: true,
          renderer: props => {
            if (props.type === 'C') {
              return 'Credit'
            }
            return 'Debit'
          },
        },
        {
          label: 'Amount',
          field: 'amount',
          useRenderer: true,
          renderer: props => `${props.amount} ${props.user ? props.user.currency_symbol : ''}`,
        },
      ],
      walletList: null,
      cardOptions: [],
      selectedCard: '0',
      amountToAdd: 0,
      mobileNumber: '',
      sendAmount: '',
      walletOptionsSelected: '0',
    }
  },
  computed: {
    walletOptions() {
      return [
        { item: '0', name: this.$t('Add Money') },
        { item: '1', name: this.$t('Send Money') },
        { item: '2', name: this.$t('Receive Money') },
      ]
    },
    labelInfo() {
      return this.walletList ? `${this.$t('Enter Amount')} ${this.walletList ? this.walletList.currency_symbol : ''}` : this.$t('Enter Amount')
    },
  },
  mounted() {
    const { userCardList } = useUserUi()
    this.getUserWallet()
    userCardList()
      .then(({ data }) => {
        if (data.statusCode === '200') {
          this.cardOptions = data.responseData.map(x => ({
            label: `**** **** **** ${x.last_four}`,
            value: x.id,
            payload: x,
          }))
          this.cardOptions.unshift({
            label: 'Select Card',
            value: '0',
          })
        }
      })
      .catch(error => {
        showErrorNotification(this, error)
      })
  },
  methods: {
    getUserWallet() {
      const { userWalletList } = useUserUi()
      userWalletList()
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.walletList = data.responseData
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    addMoneyToUser() {
      if (!this.amountToAdd || this.amountToAdd <= 0) {
        showDangerNotification(this, 'Please enter amount greater than 0')
        return
      }
      // eslint-disable-next-line eqeqeq
      const isCardExist = this.cardOptions.find(x => x.value == this.selectedCard)
      if (isCardExist && isCardExist.payload) {
        const formData = new FormData()
        formData.append('payment_mode', 'CARD')
        formData.append('user_type', 'user')
        formData.append('amount', this.amountToAdd)
        formData.append('card_id', isCardExist.payload.card_id)

        const { userAddMoney } = useUserUi()
        showLoader()
        userAddMoney(formData)
          .then(({ data }) => {
            if (data.statusCode === '200') {
              this.walletList = data.responseData
              this.$refs.walletGrid.loadItems()
              showSuccessNotification(this, 'Request processed successfully')
              this.amountToAdd = 0
              this.selectedCard = '0'
              this.getUserWallet()
            } else {
              showDangerNotification(this, data.message)
            }
            hideLoader()
          })
          .catch(error => {
            showErrorNotification(this, error)
          })
      }
    },
    sendMoneyToUser() {
      if (!this.mobileNumber) {
        showDangerNotification(this, 'Please enter mobile number')
        return
      }
      // eslint-disable-next-line no-restricted-globals
      if (parseFloat(this.sendAmount) <= 0 || isNaN(Number(this.sendAmount)) || Number(this.sendAmount) <= 0) {
        showDangerNotification(this, 'Amount should be greater than 0')
        return
      }
      const { sendMoneyToUser } = useUserUi()
      showLoader()
      const formData = new FormData()
      formData.append('mobile', `+${this.$refs.userPhoneNumber.results.countryCallingCode}${this.$refs.userPhoneNumber.results.nationalNumber}`)
      formData.append('amount', this.sendAmount)
      sendMoneyToUser(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.walletList = data.responseData
            this.$refs.walletGrid.loadItems()
            showSuccessNotification(this, 'Request processed successfully')
            this.mobileNumber = ''
            this.sendAmount = ''
            this.getUserWallet()
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';

[dir] .vs__dropdown-toggle {
  border: 1px solid #d8d6de;
}
</style>
